<template>
  <v-container fluid class="mt-5">
    <v-row justify="center">
      <v-col cols="11">
        <v-expand-transition>
          <Loader v-if="loading" />
          <v-row v-else>
            <CreateShield @created="getShields" />
            <UpdateShield :shieldData="selectedShield" @updated="getShields" />
            <DeleteShield :shieldData="selectedShield" @deleted="getShields" />

            <v-col
              cols="12"
              class="d-flex justify-end"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    height="56px"
                    class="ml-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="createShield()"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar Escudo</span>
              </v-tooltip>
            </v-col>

            <v-col
              lg="2"
              md="2"
              sm="3"
              cols="6"
              v-for="(shield, index) in shields"
              :key="shield._id"
            >
              <v-card>
                <v-btn
                  fab
                  x-small
                  color="error"
                  class="delete-btn"
                  @click="deleteShield(shield)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
                <div class="d-flex flex-column flex-no-wrap justify-center align-center pa-2" @click="updateShield(shield)">
                  <v-avatar
                    tile
                    left
                    size="80%"
                    height="100px"
                    color="#F7F7F7"
                    class="shield-image"
                  >
                    <v-img
                      v-if="shield.image"
                      :src="shield.image"
                      contain
                      max-height="60%"
                      @error="imageErrorHandler(index)"
                    />

                    <span
                      v-else
                      class="display-2 white--text text-center"
                    >
                      <v-icon>
                        mdi-shield-star
                      </v-icon>
                    </span>
                  </v-avatar>
                  <span class="mt-1">
                    {{truncate(shield.name, 20)}}
                  </span>
                </div>
              </v-card>
            </v-col>

            <v-col
              v-if="shields.length === 0"
              cols="12"
            >
              <EmptyData />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    Loader: () => import('@/components/LoadingCards'),
    EmptyData: () => import('@/components/EmptyData'),
    CreateShield: () => import('./components/CreateShield.vue'),
    UpdateShield: () => import('./components/UpdateShield.vue'),
    DeleteShield: () => import('./components/DeleteShield.vue')
  },
  data () {
    return {
      loading: false,
      shields: [],
      selectedShield: {}
    }
  },
  computed: {
    createShieldModal: {
      get () {
        return this.$store.state.createShieldModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createShieldModal',
          value: val
        })
      }
    },
    updateShieldModal: {
      get () {
        return this.$store.state.updateShieldModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateShieldModal',
          value: val
        })
      }
    },
    deleteShieldModal: {
      get () {
        return this.$store.state.deleteShieldModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteShieldModal',
          value: val
        })
      }
    }
  },
  mounted () {
    this.getShields()
  },
  methods: {
    getShields () {
      this.loading = true

      this.$http.get('/shields')
        .then((res) => {
          const { data } = res.data
          this.shields = data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
    createShield () {
      this.createShieldModal = true
    },
    updateShield (shield) {
      this.selectedShield = shield
      this.updateShieldModal = true
    },
    deleteShield (shield) {
      this.selectedShield = shield
      this.deleteShieldModal = true
    },
    imageErrorHandler (index) {
      this.shields[index].image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.shield-image {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
}

.delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 5;
}

.delete-btn:hover {
  transform: scale(1.1);
}
</style>
